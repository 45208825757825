import Dashboard from "layouts/dashboard";
// import Tables from "layouts/tables";
import AssignmentIcon from "@mui/icons-material/Assignment";
import Coin from "layouts/coin";
import AddNode from "layouts/addnode";
import EditNode from "layouts/editnode";
import SendNode from "layouts/sendnode";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import Terms from "layouts/TermsAndConditions";
import CurrencyBitcoinIcon from "@mui/icons-material/CurrencyBitcoin";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import invest from "./assets/images/invest.png";
import FAQ from "layouts/faq";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import Investment from "layouts/how_to_invest";
import SendIcon from "@mui/icons-material/Send";
import EditIcon from "@mui/icons-material/Edit";
import Articles from "layouts/articles";
import ViewNode from "layouts/viewnode";
import RevokeWecEarnings from "layouts/revokeWecEarnings";
import SetRewards from "layouts/setrewards";
import PersonIcon from "@mui/icons-material/Person";
import ReadMoreArticle from "layouts/articles/ReadMoreArticle";
import UserProfile from "layouts/userProfile";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import User from "layouts/user";
import SendReward from "layouts/sendReward";
import RevokeWecReward from "layouts/revokeWecReward";
import TotalNodes from "layouts/totalnodes";
import SendNFTAirTicket from "layouts/sendNFTAirTicket";
import EditAirTicket from "layouts/editAirTicket";
import TotalAll from "layouts/total/TotalAll";
import ViewOneUser from "layouts/ViewOneUser/ViewOneUser";
import RevokeAirRicketRewards from "layouts/RevokeAirRicketRewards/RevokeAirRicketRewards";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import WithdrawRecords from "layouts/withdrawrecords";
import InterestRate from "layouts/interest_rate";
import Coins from "layouts/Add_Staking_Coins";
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import CallToActionIcon from '@mui/icons-material/CallToAction';
import AllWithdraw from "layouts/AllWithdraw/index"
import AllUnstakeWecHistory from "layouts/AllUnstakeWecHistory";
const routes = [
  {
    // type: "collapse",
    // name: "Dashboard",
    // key: "dashboard",
    // icon: <DashboardIcon color="primary" />,
    // icon: <Icon fontSize="small">dashboard</Icon>,
    // route: "/dashboard",
    // component: <Dashboard />,
  },

  // {
  //   type: "collapse",
  //   name: "Add Node",
  //   key: "addnode",
  //   icon: <AddCircleIcon />,
  //   // icon: <img fontSize="small" src="/Images/bitcoin2.png" width={40} height={40} alt="img" />,
  //   route: "/addnode",
  //   component: <Coin />,
  // },
  // {
  //   type: "collapse",
  //   name: "Change Remaining Nodes",
  //   key: "changenode",
  //   icon: <ChangeCircleIcon />,
  //   // icon: <img fontSize="small" src="/Images/bitcoin2.png" width={40} height={40} alt="img" />,
  //   route: "/changenode",
  //   component: <AddNode />,
  // },

  // {
  //   type: "collapse",
  //   name: "Change Total Nodes",
  //   key: "totalnodes",
  //   icon: <AddCircleIcon />,
  //   // icon: <img fontSize="small" src="/Images/bitcoin2.png" width={40} height={40} alt="img" />,
  //   route: "/totalnode",
  //   component: <TotalNodes />,
  // },

  // {
  //   type: "collapse",
  //   name: "AddNews",
  //   key: "addnews",
  //   icon: <AddCircleIcon />,
  //   // icon: <img fontSize="small" src="/Images/bitcoin2.png" width={40} height={40} alt="img" />,
  //   route: "/addnews",
  //   component: <Articles />,
  // },

  // {
  //   type: "collapse",
  //   name: "Edit Nodes Price",
  //   key: "editnode",
  //   icon: <EditIcon />,
  //   // icon: <img fontSize="small" src="/Images/bitcoin2.png" width={40} height={40} alt="img" />,
  //   route: "/editnode",
  //   component: <EditNode />,
  // },

  // {
  //   type: "collapse",
  //   name: "Send Nodes ",
  //   key: "sendnode",
  //   icon: <SendIcon />,
  //   // icon: <img fontSize="small" src="/Images/bitcoin2.png" width={40} height={40} alt="img" />,
  //   route: "/sendnode",
  //   component: <SendNode />,
  // },

  // {
  //   type: "collapse",
  //   name: "Set Rewards",
  //   key: "setRewards",
  //   icon: <SendIcon />,
  //   route: "/setRewards",
  //   component: <SetRewards />,
  // },

  // {
  //   type: "collapse",
  //   name: "Send Rewards",
  //   key: "sendRewards",
  //   icon: <SendIcon />,
  //   route: "/sendRewards",
  //   component: <SendReward />,
  // },



  // {
  //   type: "collapse",
  //   name: "Send NFT Air Ticket And Rewards",
  //   key: "send NFT Air Ticket",
  //   icon: <SendIcon />,
  //   route: "/sendNFTAirTicket",
  //   component: <SendNFTAirTicket />,
  // },

  // {
  //   type: "collapse",
  //   name: "Edit NFT Air Ticket ",
  //   key: "Edit NFT Air Ticket",
  //   icon: <EditIcon />,
  //   route: "/editNFTAirTicket",
  //   component: <EditAirTicket />,
  // },

  // {
  //   type: "collapse",
  //   name: "Revoke Air Ticket Rewards",
  //   key: "revokeairticketrewards",
  //   icon: <ContentCutIcon />,
  //   route: "/revokeAirTicketRewards",
  //   component: <RevokeAirRicketRewards />,
  // },

  {
    route: "/admin/viewUsers",
    component: <ViewOneUser />,
  },

  // {
  //   type: "collapse",
  //   name: "User Investment", 
  //   key: "",
  //   icon: <Icon fontSize="small">person</Icon>,
  //   route: "/Tables",
  //   component: <Tables />,
  // },

  // {
  //   type: "collapse",
  //   name: "FAQs",
  //   key: "FAQ",
  //   icon: <LiveHelpIcon />,
  //   route: "/FAQ",
  //   component: <FAQ />,
  // },
  // {
  //   type: "collapse",
  //   name: "Terms And Conditions",
  //   key: "Terms_And_Conditions",
  //   icon: <AssignmentIcon />,
  //   route: "/Terms_And_Conditions",
  //   component: <Terms />,
  // },
  // {
  //   type: "collapse",
  //   name: "How To Invest",
  //   key: "Invest",
  //   icon: <img src={invest} alt="img" />,
  //   route: "/Invest",
  //   component: <Investment />,
  // },
  // {
  //   type: "collapse",
  //   name: "HistoryOfNode",
  //   key: "node",
  //   icon: <AssignmentIcon />,
  //   route: "/viewnodes",
  //   component: <ViewNode />,
  // },

  // {
  //   type: "collapse",
  //   name: "Withdraw Rewards",
  //   key: "withdraw-records",
  //   icon: <AssignmentIcon />,
  //   route: "/withdraw-records",
  //   component: <WithdrawRecords />,
  // },

  {
    type: "collapse",
    name: "User",
    key: "user",
    icon: <PersonIcon />,
    route: "/user",
    component: <User />,
  },
  {
    type: "collapse",
    name: "Changing Interest Rate",
    key: "Changing Interest Rate",
    icon: <TrendingUpIcon />,
    route: "interestrate",
    component: <InterestRate />,
  },
  {
    type: "collapse",
    name: "Add Staking Coins ",
    key: "Add Staking Coins ",
    icon: <ChangeCircleIcon />,
    route: "add-staking-coins",
    component: <Coins />,
  },
  {
    type: "collapse",
    name: "Revoke Rewards",
    key: "revokeRewards",
    icon: <ContentCutIcon />,
    route: "/revokeRewards",
    component: <RevokeWecReward />,
  },
  {
    type: "collapse",
    name: "Revoke Earnings",
    key: "revoke",
    icon: <ContentCutIcon />,
    route: "/revokeEarnings",
    component: <RevokeWecEarnings />,
  },
  {
    type: "collapse",
    name: "All Withdraw Data ",
    key: "All Withdraw Data ",
    icon: <CallToActionIcon />,
    route: "allwithdrawdata",
    component: <AllWithdraw />,
  },
  {
    type: "collapse",
    name: "All Unstaked History",
    key: "All Unstaked History ",
    icon: <CallToActionIcon />,
    route: "allunstakehistorydata",
    component: <AllUnstakeWecHistory />,
  },

  // {
  //   type: "collapse",
  //   name: "Total",
  //   key: "total",
  //   icon: <PersonIcon />,
  //   route: "/total",
  //   component: <TotalAll />,
  // },
  {
    // type: "collapse",
    // name: "articles",
    // key: "articles",
    // icon: <Icon fontSize="small">helpoutline</Icon>,
    route: "/readarticle",
    component: <ReadMoreArticle />,
  },

  {
    // type: "collapse",
    //name: "Sign Up",
    // key: "sign-up",
    // icon: <Icon fontSize="small">assignment</Icon>,
    route: "/profile",
    component: <UserProfile />,
  },
  {
    //   type: "collapse",
    //   name: "Sign In",
    //   key: "sign-in",
    //   icon: <Icon fontSize="small">login</Icon>,
    route: "/sign-in",
    component: <SignIn />,
  },
  {
    // type: "collapse",
    //name: "Sign Up",
    // key: "sign-up",
    // icon: <Icon fontSize="small">assignment</Icon>,
    route: "/sign-up",
    component: <SignUp />,
  },
];

export default routes;






// const columns = [
//   { Header: "ID", accessor: "id" },
//   {
//     Header: selectedOption === "withdraw" ? "Date" : selectedOption === "staking" ? "" : "Wallet Address",
//     accessor: selectedOption === "withdraw" ? "Date" : selectedOption === "staking" ? "" : "WalletAddress",
//   },
//   { Header: "Amount", accessor: "Amount" },
//   ...(selectedOption === "Staking" ? [{ Header: "Staking", accessor: "staking" }] : []),
// ];



