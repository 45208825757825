import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { FormControl } from "@mui/material";
import { axiosInstanceAuth } from "apiInstances";
import useEncryption from "EncryptDecrypt/EncryptDecrypt";
import { ToastContainer, toast } from "react-toastify";
import MDButton from "components/MDButton";
import "./revoke.css";
import NodeDetails from "layouts/nodedetails";

function RevokeReward() {
  let navigate = useNavigate();
  const [walletAddress, setWalletAddress] = useState("");
  const [quantity, setQuantity] = useState("");
  const [ABTquantity, setABTQuantity] = useState("");

  const [quantityAll, setQuantityAll] = useState("");
  const { encryptData, decryptData } = useEncryption();

  const checkToken = () => {
    const Token = localStorage.getItem("Token");
    if (!Token) {
      navigate("/sign-in");
    }
  };

  useEffect(() => {
    checkToken();
  }, []);

  const handleChange = (event) => {
    const wallet = event.target.value;
    const lowerWallet = wallet.toLowerCase();
    console.log("🚀 ~ handleChange ~ lowerWallet:", lowerWallet)
    setWalletAddress(lowerWallet);
  };

  const handleQuantityChange = (e) => {
    setQuantity(e.target.value);
  };
 const handleABTQuantityChange = (e) => {
   setABTQuantity(e.target.value);
 };
  const handleQuantityAllChange = (e) => {
    setQuantityAll(e.target.value);
  };

  // name, price
  const submitHandler = (e) => {
    e.preventDefault();

    try {
      const encryptedData = encryptData(
        JSON.stringify({
         
          amount: Number(quantity),
          type:'STAKING'
        })
      );
      axiosInstanceAuth
        .post(`revokeStakingRewards/${walletAddress}`, {
          data: encryptedData,
        })
        .then((res) => {
          console.log("🚀 ~ .then ~ res:", res)
          const responseData = decryptData(res.data.data);
          console.log("🚀 ~ .then ~ responseData:", responseData)

          if (responseData.status==true) {
            toast.success(responseData.message);
          }
          else{
            toast.error("something went wrong")
          }
        })
        .catch((res) => {
          let err = decryptData(res.data.data);
          console.log("🚀 ~ err", err);
          toast.error(err.message);
        });
    } catch (error) {
      console.log("🚀 ~ submitHandler ~ error:", error);
    }
    finally{
      setWalletAddress('');
      setQuantity('')
    }
  };

  const submitRevokeHandler = (e) => {
    e.preventDefault();

    try {
      const encryptedData = encryptData(
        JSON.stringify({
          rewards: quantityAll,
        })
      );
      axiosInstanceAuth
        .post(``, {
          data: encryptedData,
        })
        .then((res) => {
          const responseData = decryptData(res.data.data);

          if (responseData.status) {
            toast.success(responseData.message);
          }
        })
        .catch((res) => {
          let err = decryptData(res.data.data);
          toast.error(err.message);
        });
    } catch (error) {
      console.log("🚀 ~ submitRevokeHandler ~ error:", error);
    }
  };
const submitABTHandler = (e) => {
  e.preventDefault();

  try {
    const encryptedData = encryptData(
      JSON.stringify({
        amount: Number(ABTquantity),
        type: "PROFIT",
      })
    );
    axiosInstanceAuth
      .post(`revokeABTStakingRewards/${walletAddress}`, {
        data: encryptedData,
      })
      .then((res) => {
        console.log("🚀 ~ .then ~ res:", res);
        const responseData = decryptData(res.data.data);
        console.log("🚀 ~ .then ~ responseData:", responseData);

        if (responseData.status == true) {
          toast.success(responseData.message);
        } else {
          toast.error("something went wrong");
        }
      })
      .catch((res) => {
        let err = decryptData(res.data.data);
        console.log("🚀 ~ err", err);
        toast.error(err.message);
      });
  } catch (error) {
    console.log("🚀 ~ submitHandler ~ error:", error);
  } finally {
    setWalletAddress("");
    setQuantity("");
  }
};
  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        <MDBox pt={6} pb={3}>
          <Card>
            <MDBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              mt={-3}
              p={2}
              mb={1}
              textAlign="center"
              className="TitleColor heading-width"
            >
              <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                Revoke WEC Reward(s)
              </MDTypography>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
              <Box>
                <FormControl onSubmit={submitHandler}>
                  <MDBox component="form" role="form">
                    <MDBox mb={2}>
                      <MDInput
                        type="text"
                        name="UserType"
                        label="walletAddress"
                        value={walletAddress}
                        onChange={handleChange}
                        required
                      />
                    </MDBox>

                    <MDBox mb={2}>
                      <MDInput
                        type="text"
                        name="UserType"
                        label="Rewards"
                        value={quantity}
                        onChange={handleQuantityChange}
                        required
                      />
                      {/* <div className="changeFontSize">
                        Rewards per node will not be considered. It will be sent as it is.
                      </div> */}
                    </MDBox>
                    <MDBox mt={4} mb={1}>
                      <MDButton
                        variant="gradient"
                        color="info"
                        type="submit"
                        className="TitleColor"
                      >
                        Submit
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </FormControl>
              </Box>
            </MDBox>
          </Card>
        </MDBox>

        <MDBox pt={6} pb={3}>
          <Card>
            <MDBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              mt={-3}
              p={2}
              mb={1}
              textAlign="center"
              className="TitleColor heading-width"
            >
              <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                Revoke ABT Reward(s)
              </MDTypography>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
              <Box>
                <FormControl onSubmit={submitABTHandler}>
                  <MDBox component="form" role="form">
                    <MDBox mb={2}>
                      <MDInput
                        type="text"
                        name="UserType"
                        label="walletAddress"
                        value={walletAddress}
                        onChange={handleChange}
                        required
                      />
                    </MDBox>

                    <MDBox mb={2}>
                      <MDInput
                        type="text"
                        name="UserType"
                        label="Rewards"
                        value={ABTquantity}
                        onChange={handleABTQuantityChange}
                        required
                      />
                    </MDBox>
                    <MDBox mt={4} mb={1}>
                      <MDButton
                        variant="gradient"
                        color="info"
                        type="submit"
                        className="TitleColor"
                      >
                        Submit
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </FormControl>
              </Box>
            </MDBox>
          </Card>
        </MDBox>

        <Footer />
      </DashboardLayout>
      <ToastContainer />
    </>
  );
}

export default RevokeReward;
