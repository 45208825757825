// import MDBox from "components/MDBox";
// import React, { useEffect, useState } from "react";
// import Card from "@mui/material/Card";
// import MDTypography from "components/MDTypography";
// import DataTable from "examples/Tables/DataTable";
// import useEncryption from "EncryptDecrypt/EncryptDecrypt";
// import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
// import { axiosInstanceAuth } from "apiInstances";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import Footer from "examples/Footer";
// import MDButton from "components/MDButton";
// import Pagination from "@mui/material/Pagination";
// import Stack from "@mui/material/Stack";
// import Grid from "@mui/material/Grid";
// import { TextField } from "@mui/material";

// const WithdrawRecords = () => {
//   let navigate = useNavigate();
//   const [requestData, setRequestData] = useState([]);
//   const { decryptData } = useEncryption();
//   const [loading, setLoading] = useState(true);
//   const [page, setPage] = useState(1);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [searchTerm, setSearchTerm] = useState("");
//   const [debounceTimeout, setDebounceTimeout] = useState(null);

//   const entriesPerPage = 200;
//   const checkToken = () => {
//     const Token = localStorage.getItem("Token");
//     if (!Token) {
//       navigate("/sign-in");
//     }
//   };

//   const getWithdrawRecords = async () => {
//     try {
//       axiosInstanceAuth
//         .get(`/allWithdrawHistory/${currentPage}`)
//         .then((res) => {
//           const responseData = decryptData(res?.data?.data);
//           console.log("🚀 ~ .then ~ responseData:", responseData);
//           const totalPages = responseData?.data?.totalPages;
//           setPage(totalPages);
//           setLoading(false);
//           const start = (currentPage - 1) * entriesPerPage;
//           const end = start + entriesPerPage;
//           const temp = responseData?.data?.allWithdraws?.map((data, index) => {
//             return {
//               id: (
//                 <MDTypography component="a" variant="button" color="text" fontWeight="medium">
//                   {start + index + 1}
//                 </MDTypography>
//               ),
//               walletAddress: (
//                 <MDTypography component="a" variant="button" color="text" fontWeight="medium">
//                   {`${data.to}`}
//                 </MDTypography>
//               ),
//               Amount: (
//                 <MDTypography component="a" variant="button" color="text" fontWeight="medium">
//                   {data.amount}
//                 </MDTypography>
//               ),
//             };
//           });

//           setRequestData(temp);
//           setLoading(false);
//         })
//         .catch((res) => {
//           toast.error(res.data.message);
//         });
//     } catch (error) {
//       console.log("🚀 ~ getUser ~ error:", error);
//     }
//   };

//   useEffect(() => {
//     checkToken();
//     getWithdrawRecords();
//   }, [currentPage]);

//   const columns = [
//     { Header: "id", accessor: "id", align: "left" },
//     { Header: "walletAddress", accessor: "walletAddress", align: "left" },
//     { Header: "Amount", accessor: "Amount", align: "left" },
//   ];

//   const handlePageChange = (event, newPage) => {
//     if (typeof newPage === "number") {
//       setCurrentPage(newPage);
//       console.log("new page: " + newPage);
//     }
//   };
//   const handleInput = (e) => {
//     const searchdata = e.target.value.trim();
//     setSearchTerm(searchdata);

//     if (debounceTimeout) {
//       clearTimeout(debounceTimeout);
//     }

//     const newDebounceTimeout = setTimeout(async () => {
//       if (searchdata === "") {
//         getWithdrawRecords();
//       } else {
//         try {
//           // setLoadingTable(dispatch, true)
//           // setLoading(true)
//           await axiosInstanceAuth
//             .get(`/searchWithdraws`, {
//               params: {
//                 walletAddress: searchdata,
//                 page: currentPage,
//               },
//             })
//             .then((res) => {
//               console.log("🚀 ~ .then ~ res=====================:", res);
//               const responseData = decryptData(res?.data?.data);
//               console.log("🚀 ~ .then ~ responseData:", responseData);
//               const start = (currentPage - 1) * entriesPerPage;

//               const temp = responseData?.data?.allWithdraws?.map((data, index) => {
//                 return {
//                   id: (
//                     <MDTypography component="a" variant="button" color="text" fontWeight="medium">
//                       {start + index + 1}
//                     </MDTypography>
//                   ),
//                   walletAddress: (
//                     <MDTypography component="a" variant="button" color="text" fontWeight="medium">
//                       {`${data.to}`}
//                     </MDTypography>
//                   ),
//                   Amount: (
//                     <MDTypography component="a" variant="button" color="text" fontWeight="medium">
//                       {data.amount}
//                     </MDTypography>
//                   ),
//                 };
//               });

//               setRequestData(temp);
//               setLoading(false);
//               setPage(responseData?.data?.totalPages);
//             });
//         } catch (error) {
//           console.error(error);
//         }
//       }
//     }, 500);

//     setDebounceTimeout(newDebounceTimeout);
//   };

//   return (
//     <>
//       <DashboardNavbar />
//       <DashboardLayout>
//         {loading ? (
//           <div className="snippet" data-title=".dot-spin">
//             <div className="stage">
//               <div className="dot-spin"></div>
//             </div>
//           </div>
//         ) : (
//           <MDBox pt={6} pb={3}>
//             <Grid container spacing={3}>
//               <Grid item xs={12}>
//                 <Card>
//                   <MDBox
//                     mx={2}
//                     mt={-3}
//                     py={3}
//                     px={2}
//                     variant="gradient"
//                     bgColor="coinTitleColor"
//                     borderRadius="lg"
//                     coloredShadow="info"
//                   >
//                     <MDTypography variant="h6" color="white">
//                       All Withdraw Data
//                     </MDTypography>
//                   </MDBox>
//                   <MDBox pt={3}>
//                     {/* <MDBox pr={1} classNane="inputsre"> */}
//                     {/* ... (other code) */}
//                     {/* </MDBox> */}
//                     <MDBox pr={1} className=" space-x-2">
//                       <TextField
//                         type="text"
//                         label="Search here"
//                         onChange={handleInput}
//                         className="p-2"
//                       />
//                     </MDBox>

//                     <DataTable
//                       table={{ columns: columns, rows: requestData }}
//                       // isSorted={true}
//                       entriesPerPage={false}
//                       showTotalEntries={false}
//                       noEndBorder
//                     />

//                     <Stack padding={2}>
//                       <Pagination
//                         onChange={handlePageChange}
//                         count={page} // Assuming 'page' is the total number of pages
//                         size="large"
//                         defaultPage={1} // Set the default highlighted page
//                         page={currentPage} // Highlight the current page
//                       />
//                     </Stack>
//                   </MDBox>
//                 </Card>
//               </Grid>
//             </Grid>
//           </MDBox>
//         )}
//         <Footer />
//       </DashboardLayout>
//     </>
//   );
// };

// export default WithdrawRecords;

import { Grid, Stack, Pagination, TextField, ButtonGroup, Button } from "@mui/material";
import MDBox from "components/MDBox";
import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import useEncryption from "EncryptDecrypt/EncryptDecrypt";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosInstanceAuth } from "apiInstances";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import MDButton from "components/MDButton";

const AllUnstakeWecHistory = () => {
  let navigate = useNavigate();
  const [requestData, setRequestData] = useState([]);
  const { decryptData } = useEncryption();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [totalunsteakedwec, setTotalUnsteakedwec] = useState(null);
  const [totalunsteakedABT, setTotalUnsteakedABT] = useState(null);
  const [selectedButton, setSelectedButton] = useState("WEC");
  const [wecData, setWecData] = useState([]);
  const [ABTData, setABTData] = useState([]);
  const [wecpage, setWecPage] = useState(1);
  const [abtpage, setAbtPage] = useState(1);


  const entriesPerPage = 100;
  const checkToken = () => {
    const Token = localStorage.getItem("Token");
    if (!Token) {
      navigate("/sign-in");
    }
  };

  const getWithdrawRecords = async () => {
    try {
      axiosInstanceAuth
        .get(`/allUnstakedWecABTHistory/${currentPage}`)
        .then((res) => {
          const responseData = decryptData(res?.data?.data);
          console.log("🚀 ~ .then ~ responseData:", responseData)
          setTotalUnsteakedwec(responseData?.data?.totalunstedewc)
          setTotalUnsteakedABT(responseData?.data?.totalUnStakedABT)

          // const totalPages = responseData?.data?.totalPages;
          const totalWECPages = responseData?.data?.totalWECPages;
          const totalABTPages = responseData?.data?.totalABTPages;

          // setPage(totalPages);

          setWecPage(totalWECPages);
          setAbtPage(totalABTPages)

          setLoading(false);
          const start = (currentPage - 1) * entriesPerPage;
          const end = start + entriesPerPage;
          const temp = responseData?.data?.allUnstedWecHistory?.map((data, index) => {
            return {
              id: (
                <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                  {start + index + 1}
                </MDTypography>
              ),
              transaction_hash: (
                <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                  {`${data.txhash}`}
                </MDTypography>
              ),
              walletAddress: (
                <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                  {`${data.toWalletAddress}`}
                </MDTypography>
              ),
              UnstakedAmount: (
                <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                  {data.unStakeWec}
                </MDTypography>
              ),
              createdAt: (
                <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                  {new Date(data.createdAt.toString()).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                  })}
                </MDTypography>
              ),
            };
          });
          setWecData(temp);

          const temp1 = responseData?.data?.allUnstedABtHistory?.map((data, index) => {
            return {
              id: (
                <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                  {start + index + 1}
                </MDTypography>
              ),
              transaction_hash: (
                <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                  {`${data.txhash}`}
                </MDTypography>
              ),
              walletAddress: (
                <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                  {`${data.toWalletAddress}`}
                </MDTypography>
              ),
              UnstakedAmount: (
                <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                  {data.unStakeWec}
                </MDTypography>
              ),
              createdAt: (
                <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                  {new Date(data.createdAt.toString()).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                  })}
                </MDTypography>
              ),
            };
          });
          setABTData(temp1);

          setRequestData(temp);
          setLoading(false);
        })
        .catch((res) => {
          toast.error(res.data.message);
        });
    } catch (error) {
      console.log("🚀 ~ getUser ~ error:", error);
    }
  };

  useEffect(() => {
    checkToken();
    getWithdrawRecords();
  }, [currentPage]);

  const columns = [
    { Header: "id", accessor: "id", align: "left" },
    { Header: "transaction_hash", accessor: "transaction_hash", align: "left" },

    { Header: "walletAddress", accessor: "walletAddress", align: "left" },
    { Header: "UnstakedAmount", accessor: "UnstakedAmount", align: "left" },
    { Header: "createdAt", accessor: "createdAt", align: "left" },
  ];

  const handlePageChange = (event, newPage) => {
    if (typeof newPage === "number") {
      setCurrentPage(newPage);
      console.log("new page: " + newPage);
    }
  };
  const handleInput = (e) => {
    const searchdata = e.target.value.trim();
    setSearchTerm(searchdata);

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const newDebounceTimeout = setTimeout(async () => {
      if (searchdata === "") {
        getWithdrawRecords();
      } else {
        try {
          await axiosInstanceAuth
            .get(`/searchUnstakData`, {
              params: {
                walletAddress: searchdata,
                page: currentPage,
              },
            })
            .then((res) => {
              const responseData = decryptData(res?.data?.data);
              console.log("🚀 ~ .searchUnstakData ~ responseData:", responseData)
              const start = (currentPage - 1) * entriesPerPage;

              const temp = responseData?.data?.allunstakeWECdata?.map((data, index) => {
                return {
                  id: (
                    <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                      {start + index + 1}
                    </MDTypography>
                  ),
                  transaction_hash: (
                    <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                      {`${data.txhash}`}
                    </MDTypography>
                  ),
                  walletAddress: (
                    <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                      {`${data.toWalletAddress}`}
                    </MDTypography>
                  ),
                  UnstakedAmount: (
                    <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                      {data.unStakeWec}
                    </MDTypography>
                  ),
                  createdAt: (
                    <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                      {new Date(data.createdAt.toString()).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        second: "numeric",
                      })}
                    </MDTypography>
                  ),
                };
              });
              const temp1 = responseData?.data?.allunstakABTCdata?.map((data, index) => {
                return {
                  id: (
                    <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                      {start + index + 1}
                    </MDTypography>
                  ),
                  transaction_hash: (
                    <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                      {`${data.txhash}`}
                    </MDTypography>
                  ),
                  walletAddress: (
                    <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                      {`${data.toWalletAddress}`}
                    </MDTypography>
                  ),
                  UnstakedAmount: (
                    <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                      {data.unStakeWec}
                    </MDTypography>
                  ),
                  createdAt: (
                    <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                      {new Date(data.createdAt.toString()).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        second: "numeric",
                      })}
                    </MDTypography>
                  ),
                };
              });
              setWecData(temp);
              setABTData(temp1);
              // setRequestData(temp);
              setLoading(false);
              setPage(responseData?.data?.totalPages);
            });
        } catch (error) {
          console.error(error);
        }
      }
    }, 500);

    setDebounceTimeout(newDebounceTimeout);
  };

  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        {loading ? (
          <div className="snippet" data-title=".dot-spin">
            <div className="stage">
              <div className="dot-spin"></div>
            </div>
          </div>
        ) : (
          <>
            <div className="text-center  mt-5">
              <ButtonGroup variant="contained" color="primary">
                <Button
                  onClick={() => setSelectedButton("WEC")}
                  variant={selectedButton === "WEC" ? "contained" : "outlined"}
                  style={{
                    backgroundColor: selectedButton === "WEC" ? "blue" : "white",
                    color: selectedButton === "WEC" ? "white" : "blue",
                  }}
                >
                  WEC
                </Button>
                <Button
                  onClick={() => setSelectedButton("ABT")}
                  variant={selectedButton === "ABT" ? "contained" : "outlined"}
                  style={{
                    backgroundColor: selectedButton === "ABT" ? "blue" : "white",
                    color: selectedButton === "ABT" ? "white" : "blue",
                  }}
                >
                  ABT
                </Button>
              </ButtonGroup>
            </div>
            <MDBox pt={6} pb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Card>
                    <MDBox
                      mx={2}
                      mt={-3}
                      py={3}
                      px={2}
                      variant="gradient"
                      bgColor="coinTitleColor"
                      borderRadius="lg"
                      coloredShadow="info"
                    >
                      <div className="flex justify-between items-center">

                        <MDTypography variant="h6" color="white" sx={{ fontSize: "24px" }}>
                          All Withdraw Data
                        </MDTypography>
                        <MDTypography variant="h6" color="white" sx={{ fontSize: "24px" }}>
                          Total UnStaked Amount: {selectedButton === "WEC" ? totalunsteakedwec : totalunsteakedABT}
                        </MDTypography>
                      </div>
                    </MDBox>
                    <MDBox pt={3}>
                      <MDBox pr={1} className=" space-x-2">
                        <TextField
                          type="text"
                          label="Search here"
                          onChange={handleInput}
                          className="p-2"
                        />
                      </MDBox>

                      <DataTable
                        table={{ columns: columns, rows: selectedButton === "WEC" ? wecData : selectedButton === "ABT" ? ABTData : requestData }}
                        entriesPerPage={false}
                        showTotalEntries={false}
                        noEndBorder
                      />

                      <Stack padding={2}>
                        <Pagination
                          onChange={handlePageChange}
                          count={selectedButton === "WEC" ? wecpage : selectedButton === "ABT" ? abtpage : page}
                          size="large"
                          page={currentPage}
                          defaultPage={1} // Set the default highlighted page
                        />
                      </Stack>
                    </MDBox>
                  </Card>
                </Grid>
              </Grid>
            </MDBox>
          </>
        )}
        <Footer />
      </DashboardLayout>
    </>
  );
};

export default AllUnstakeWecHistory;
