import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Box from "@mui/material/Box";
import { useState } from "react";
import { FormControl } from "@mui/material";
import { axiosInstanceAuth } from "apiInstances";
import useEncryption from "EncryptDecrypt/EncryptDecrypt";
import { ToastContainer, toast } from "react-toastify";
import MDButton from "components/MDButton";
import { ButtonGroup, Button } from "@mui/material";
function Interest() {
  const [rate, SetRate] = useState();
  const [ABTrate, SetABTRate] = useState();

  const [duration, SetDuration] = useState("12");
  console.log("🚀 ~ file: index.js:18 ~ Interest ~ duration:", duration)
  const { encryptData, decryptData } = useEncryption();
  const submitHandler = (e) => {
    e.preventDefault();
    if (!rate || !duration) {
      toast.error("please enter missing field data");
    } else {
      try {
        const encryptedData = encryptData(
          JSON.stringify({
            newRate: rate,
            duration: duration,
          })
        );
        axiosInstanceAuth
          .post(`/changeIntrestRate`, {
            data: encryptedData,
          })
          .then((res) => {
            const responseData = decryptData(res.data.data);
            console.log("Response", responseData);
            toast.success("interest rate change successfully");
            SetRate("");
            SetDuration("");
          })
          .catch((res) => {
            let err = decryptData(res.data.data);
            console.log("🚀 ~ err", err);
            toast.error(err.message);
          });
      } catch (error) {
        console.error(error);
      }
    }
  };
  const ABTsubmitHandler = (e) => {
    e.preventDefault();
    if (!ABTrate) {
      toast.error("please enter missing field data");
    } else {
      try {
        const encryptedData = encryptData(
          JSON.stringify({
            newRate: ABTrate,
            //  duration: duration,
          })
        );
        axiosInstanceAuth
          .post(`/changeABTIntrestRate`, {
            data: encryptedData,
          })
          .then((res) => {
            const responseData = decryptData(res.data.data);
            console.log("Response", responseData);
            toast.success("interest rate change successfully");
            SetRate("");
            SetDuration("");
          })
          .catch((res) => {
            let err = decryptData(res.data.data);
            console.log("🚀 ~ err", err);
            toast.error(err.message);
          });
      } catch (error) {
        console.error(error);
      }
    }
  };
  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        <MDBox pt={6} pb={3}>
          <Card>
            <MDBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              mt={-3}
              py={2}
              px={2}
              textAlign="center"
              className="TitleColor heading-width"
            >
              <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                Change WEC Interest Rate
              </MDTypography>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
              <Box>
                <FormControl onSubmit={submitHandler}>
                  <label htmlFor="interestRate">Interest Rate</label>
                  <input
                    type="number"
                    id="interestRate"
                    name="interestRate"
                    className="border border-black rounded-md mb-5"
                    onChange={(e) => SetRate(e.target.value)}
                    placeholder="0.6%"
                    value={rate}
                    required
                  />
                  <label htmlFor="duration">Choose Node(duration)</label>

                  <div className="text-center  mt-5">
                    <ButtonGroup variant="contained" color="primary">
                      <Button
                        onClick={() => SetDuration("12")}
                        variant={duration === "12" ? "contained" : "outlined"}
                        style={{
                          backgroundColor: duration === "12" ? "blue" : "white",
                          color: duration === "12" ? "white" : "blue",
                        }}
                      >
                        12 months
                      </Button>
                      <Button
                        onClick={() => SetDuration("6")}
                        variant={duration === "6" ? "contained" : "outlined"}
                        style={{
                          backgroundColor: duration === "6" ? "blue" : "white",
                          color: duration === "6" ? "white" : "blue",
                        }}
                      >
                        6 months
                      </Button>
                    </ButtonGroup>
                  </div>
                  <MDBox component="form" role="form">
                    <MDBox mt={4} mb={1}>
                      <MDButton
                        variant="gradient"
                        color="info"
                        type="submit"
                        className="TitleColor"
                      >
                        Submit
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </FormControl>
              </Box>
            </MDBox>
          </Card>
        </MDBox>
        <MDBox pt={6} pb={3}>
          <Card>
            <MDBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              mt={-3}
              py={2}
              px={2}
              textAlign="center"
              className="TitleColor heading-width"
            >
              <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                Change ABT Interest Rate
              </MDTypography>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
              <Box>
                <FormControl onSubmit={ABTsubmitHandler}>
                  <label htmlFor="interestRate">Interest Rate</label>
                  <input
                    type="number"
                    id="interestRate"
                    name="interestRate"
                    className="border border-black rounded-md mb-5"
                    onChange={(e) => SetABTRate(e.target.value)}
                    placeholder="0.5%"
                    value={rate}
                    required
                  />
                  <label htmlFor="duration"> Node(duration)</label>

                  <div className="  mt-5">
                    <Button
                      // onClick={() => SetDuration("12")}
                      // variant={duration === "12" ? "contained" : "outlined"}
                      style={{
                        backgroundColor: "blue",
                        color: "white",
                      }}
                    >
                      12 months
                    </Button>
                  </div>
                  <MDBox component="form" role="form">
                    <MDBox mt={4} mb={1}>
                      <MDButton
                        variant="gradient"
                        color="info"
                        type="submit"
                        className="TitleColor"
                      >
                        Submit
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </FormControl>
              </Box>
            </MDBox>
          </Card>
        </MDBox>

        <Footer />
      </DashboardLayout>
      <ToastContainer />
    </>
  );
}

export default Interest;













